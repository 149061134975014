import Swal from "sweetalert2";
import { Fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import Swiper from "swiper";
import { EffectFade, Scrollbar, Autoplay, Navigation, Pagination, Thumbs, Grid} from 'swiper/modules';
import 'swiper/css/grid';
import 'swiper/css/effect-fade';
import lozad from 'lozad';
import 'jquery-datetimepicker';


( function( $ ) {
    $( document ).ready(function() {

        $('.cart__popup-open').on("click", function (e) {
            e.preventDefault();
            Swal.fire({
                html: `
                    <div class="swal_inner">
                        <div class="cart">
                            <p class="cart__title h2">Basket</p>
                            <div class="cart__table">
                                <div class="cart__table_item">
                                    <a href="#" class="cart__table_img">
                                        <img class="lozad" src="/static/images/product.png" alt="img">
                                    </a>
                                    <div class="cart__table_caption">
                                        <a href="#" class="cart__table_title h3">Mexico Chiapas Zafiro SHG EP</a>
                                        <p>500 gram</p>
                                        <p class="cart__table_quantity">1 piece</p>
                                    </div>
                                    <p class="cart__table_price">70 HUF</p>
                                    <span class="cart__table_remove" title="remove"></span>
                                </div>
                                <div class="cart__table_item">
                                    <a href="#" class="cart__table_img">
                                        <img class="lozad" src="/static/images/product.png" alt="img">
                                    </a>
                                    <div class="cart__table_caption">
                                        <a href="#" class="cart__table_title h3">Mexico Chiapas Zafiro SHG EP</a>
                                        <p>500 gram</p>
                                        <p class="cart__table_quantity">1 piece</p>
                                    </div>
                                    <p class="cart__table_price">70 HUF</p>
                                    <span class="cart__table_remove" title="remove"></span>
                                </div>
                            </div>
                            <div class="cart__total">
                                <p>Total</p>
                                <p class="product-price">140 HUF</p>
                            </div>
                            <div class="cart__buttons-group">
                                <a href="#" class="button button--light">Continue shopping</a>
                                <a href="#" class="button">Place an order</a>
                            </div>
                        </div>
                    </div>
                      `,
                showCloseButton: true,
                showConfirmButton: false,
                showCancelButton: false,
            })

        });


    /*https://xdsoft.net/jqplugins/datetimepicker/*/

         function date_picker_init() {

             jQuery('.datepicker__input').datetimepicker({
                 format: 'Y/m/d',
                 minDate: 0,
                 timepicker: false
             });

             jQuery('.timepicker__input').datetimepicker({
                  datepicker:false,
                  format:'H:i'
             });
         }

         date_picker_init();

        $('.reserve-table__popup-open').on("click", function (e) {
            e.preventDefault();
            Swal.fire({
                html: `
                        <div class="swal_inner">
                            <p class="h2 title">Reserve a table</p>
                            <form action="" class="form">
                                <div class="form__grid">
                                    <div class="form__fildset">
                                        <input class="form__input" type="text" placeholder="Name" name="name" required>
                                    </div>
                                    <div class="form__fildset">
                                        <input class="form__input" type="tel" placeholder="Phone" name="phone" required>
                                    </div>
                                    <div class="form__fildset">
                                        <input class="form__input form__select datepicker__input" type="text" placeholder="Date" readonly>
                                    </div>
                                    <div class="form__fildset">
                                        <input class="form__input form__select timepicker__input" type="text" placeholder="Time" readonly>
                                    </div>
                                    <div class="form__fildset">
                                        <select class="form__input" name="guests">
                                            <option value="0">Number of persons</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                        </select>
                                    </div>
                                    <div class="form__fildset">
                                        <select class="form__input" name="location">
                                            <option value="0">Location to choose</option>
                                            <option value="1">Rumbach Sebestyén utca 12, ROASTERY</option>
                                            <option value="2">Holló utca 10, SPECIALTY</option>
                                            <option value="3">Gozsdu udvar,  BISTRO-EATERY</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form__fildset">
                                    <textarea name="comment" class="form__input" placeholder="Write a comment"></textarea>
                                </div>
                                <button type="submit" class="form__button button">Send</button>
                            </form>
                        </div>
                      `,
                showCloseButton: true,
                showConfirmButton: false,
                showCancelButton: false,
                onOpen: () => {
                    date_picker_init();
                }
            })

        });

        const observer = lozad();
        observer.observe();

        $('.navbar_toggler').on('click',function (){
            $(this).toggleClass('active')
            $('.header__container').toggleClass('active')
            $('.header nav').toggleClass('active')
        })

        $('.header nav a').on('click',function (){
            $('.header nav').removeClass('active')
            $('.header__container').removeClass('active')
            $('.navbar_toggler').removeClass('active')
        })

        $('.header .submenu_show .header__nav-link').on('click',function (){
            $(this).next().addClass('open')
            $(this).parent().addClass('open')
        })

        $('.header .header__nav-submenu--back').on('click',function (){
            $(this).parent().removeClass('open')
            $(this).parent().parent().removeClass('open')
        })

        const sectionSwiperParentSelector = document.querySelectorAll('.section__slider_wrap');
        sectionSwiperParentSelector.forEach((slider_wrap) => {
            const sectionSwiperSelector = slider_wrap.querySelector('.section__slider');
            if (sectionSwiperSelector != null) {
                const sectionSwiperPrev = slider_wrap.querySelector('.swiper-button-prev');
                const sectionSwiperNext = slider_wrap.querySelector('.swiper-button-next');
                const sectionSwiperPagination = slider_wrap.querySelector('.swiper-pagination');
                const sectionSwiper = new Swiper(sectionSwiperSelector, {
                    modules: [Navigation, Pagination],
                    slidesPerView: 1,
                    spaceBetween: 12,
                    navigation: {
                        prevEl: sectionSwiperPrev,
                        nextEl: sectionSwiperNext,
                    },
                    pagination: {
                        el: sectionSwiperPagination,
                        clickable: true,
                    },
                    breakpoints: {
                        576: {
                            slidesPerView: 2,
                            spaceBetween: 24,
                        },
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 34,
                        },
                        1200: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        }
                    }
                });
            }
        })

        const indexGallerySwiperSelector = document.querySelector('.index-gallery__slider');
        const indexGallerySwiper = new Swiper(indexGallerySwiperSelector, {
            modules: [Navigation, Pagination, Grid],
            slidesPerView: 1,
            spaceBetween: 12,
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },

            breakpoints: {
                576: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 34,
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                    grid: {
                      rows: 2,
                      fill: 'row'
                    },
                }
            }
        });

        const reviewsGallerySwiperSelector = document.querySelector('.reviews__slider');
        const reviewsGallerySwiper = new Swiper(reviewsGallerySwiperSelector, {
            modules: [Navigation, Pagination, Grid],
            slidesPerView: 1,
            spaceBetween: 12,
            navigation: {
                prevEl: ".reviews-button-prev",
                nextEl: ".reviews-button-next",
            },
            pagination: {
                el: '.reviews-pagination',
                clickable: true,
            },

            breakpoints: {
                576: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                },
                992: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                    grid: {
                      rows: 2,
                      fill: 'row'
                    },
                }
            }
        });

        const productGallerySwiperSelector = document.querySelector('.product-gallery__slider');
        const productGallerySwiper = new Swiper(productGallerySwiperSelector, {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            spaceBetween: 12,
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        });

        const blogSwiperSelector = document.querySelector('.blog__slider');
        const blogSwiper = new Swiper(blogSwiperSelector, {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            spaceBetween: 12,
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },

            breakpoints: {
                576: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 34,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                }
            }
        });

    const sliderMainSwiperSelector = document.querySelector('.swiper_gallery_main');
    const sliderTrumbSwiperSelector = document.querySelector('.swiper_gallery_trumb');

    if(sliderMainSwiperSelector != null && sliderTrumbSwiperSelector != null) {

        const ThumbSwiper = new Swiper(sliderTrumbSwiperSelector, {
            modules: [Grid],
            spaceBetween: 8,
            slidesPerView: 2,
            grid: {
              rows: 3,
              fill: 'row'
            },
            breakpoints: {
                768: {

                }
            }
        });
        const mainSwiper = new Swiper(sliderMainSwiperSelector, {
            modules: [EffectFade,Navigation, Pagination, Thumbs],
            slidesPerView: 1,
            spaceBetween: 12,
            effect: "fade",
            thumbs: {
                swiper: ThumbSwiper,
            },
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
            pagination: {
                el: ".swiper-pagination",
                type: "fraction",
            },
        });
    }


    Fancybox.bind("[data-fancybox]");


    $('.anchor[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            let target = $(this.hash);
            let menuHeight = $('header').outerHeight();
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top - menuHeight
                }, 1000);
                return false;
            }
        }
    });


    $('.footer__go-to-top').click(function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: ($("body").offset().top)
        }, 1000);
     });


});
}( jQuery ) );